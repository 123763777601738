import { log } from "~/composables/log";

export default defineNuxtRouteMiddleware(() => {
    const objectStorage = __subscription().getSubscriptionObjectStorage();
    if (objectStorage && objectStorage.isTrial) {
        log("❌ Trial S3");
        return navigateTo("/");
    }

    log("✅ Not trial S3");
});
